import styled from 'styled-components'
import {
  darkBlue,
  lightBlueFontColor,
} from '../../../../../../assets/styles/variables'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'

export const Container = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 20px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const Title = styled.h2`
  color: ${darkBlue};
  flex-grow: 2;
  @media (max-width: 1300px) {
    margin-bottom: 5px;
  }
`

export const Bookmark = styled.h2`
  color: ${lightBlueFontColor};
  margin-top: 5px;
  @media (max-width: 1300px) {
    margin-bottom: 0;
  }
`

export const Subtitle = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1300px) {
    margin-bottom: 10px;
  }
`
