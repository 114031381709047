import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
  FilterOption,
  FilterOptionValue,
  FilterTypes,
} from '../../../../../../types'
import Divider from '../../../../../../../../../../components/UI/YogiDivider'
import React from 'react'
import styled from 'styled-components'
import { YogiButton } from '../../../../../../../../../../components/UI/YogiButton'
import { StyledSpin } from '../../FilterPanelSelector.styles'

type Props = {
  option: FilterOption
  search: string
  propOption: FilterOption | undefined
  storedValues: FilterOptionValue[]
  applyEveryFilter: (search: string) => void
  clearFilters: (search: string) => void
  currentAppliedFilter: AppliedFilterOption | undefined
  floatValues: boolean
  setFloatValues: (value: boolean) => void
}

export const SelectorFooter: React.FC<Props> = ({
  option,
  search,
  propOption,
  storedValues,
  applyEveryFilter,
  clearFilters,
  currentAppliedFilter,
  floatValues,
  setFloatValues,
}) => {
  return (
    <>
      {option.filter_type === FilterTypes.SELECT &&
        option.values.length > 0 && (
          <>
            <Divider />
            <ButtonWrapper>
              <ButtonInnerWrapper>
                <YogiButton
                  type={'ghost'}
                  onClick={() => clearFilters(search)}
                  disabled={
                    !(currentAppliedFilter as AppliedFilterOptionSelect)?.values
                      ?.length ||
                    (option.field === 'sentiment' &&
                      !!(currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values.length &&
                      (currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values[0] === '0')
                  }
                >
                  Deselect All
                </YogiButton>
                <YogiButton
                  type={'ghost'}
                  onClick={() => applyEveryFilter(search)}
                >
                  Select All
                </YogiButton>
              </ButtonInnerWrapper>
              {/* TODO - probably check if stable filter list has entry and && with filteroptionsloading*/}
              <ButtonInnerWrapper>
                {!propOption?.filter_type || !storedValues ? (
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <StyledSpin />
                    <LoadingCounts>Loading Review Counts</LoadingCounts>
                  </div>
                ) : (
                  <YogiButton onClick={() => setFloatValues(!floatValues)}>
                    {floatValues
                      ? 'Sort Alphabetically'
                      : 'Sort by Review Count'}
                  </YogiButton>
                )}
              </ButtonInnerWrapper>
            </ButtonWrapper>
          </>
        )}
    </>
  )
}
export default SelectorFooter

const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
`
const ButtonInnerWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`
const LoadingCounts = styled.div`
  margin-top: -5px;
`
