import React, { useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import { useFormik } from 'formik'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { ModalWrapper, ModalHeader } from 'shared/wrappers'
import { Selector, EditProject } from './components'
import { getProjectProducts, setProjectSources, uploadFile } from './model'
import { ProjectStates, ProjectActions, projectMachine } from './machine'
import { ProductForm } from './features/product/product-form'
import { CsvForm } from './features/csv/csv-form'
import { SourceForm } from './features/source'
import { projectEditValidationSchema as validationSchema } from './validators'
import { FormAdditionalValues } from './types'
import { transformProducts } from './utils'
import { ProjectSourceFile } from './features/source/types'
import { getProject } from '../project/model'

type Props = { handleClose: () => void; projectId: string }

const initialValues: FormAdditionalValues = {
  products: [],
  sources: [],
}

export const ProjectEditForm: React.FC<Props> = ({
  handleClose,
  projectId,
}) => {
  const { data: details } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    {
      refetchOnMount: false,
      enabled: !!projectId,
    }
  )
  const [sourceToEditIndex, setSourceToEditIndex] = useState<null | number>(
    null
  )
  const [productToEditIndex, setProductToEditIndex] = useState<null | number>(
    null
  )
  const [current, send] = useMachine(projectMachine)
  const {
    data: existingProducts,
    isLoading,
    refetch,
  } = useQuery(['products-list'], () => getProjectProducts(projectId), {
    enabled: false,
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: false,
    onSubmit: async ({ sources, products }, { setFieldError }) => {
      if (sources.some((source) => !source.product_id)) {
        setFieldError('sources', 'Every source must be assigned to a product.')
        return
      }

      try {
        const existingProductsPayload = (existingProducts || [])
          .filter((product) =>
            sources.find((source) => source.product_id === product.uuid)
          )
          .map((product) => ({ name: product.name, id: product.uuid }))

        const fileSources = sources.filter(
          ({ file }: any) => !!file
        ) as ProjectSourceFile[]

        await Promise.all(
          fileSources.map(({ key, bucket, file }) =>
            uploadFile(key, file, bucket)
          )
        )

        await setProjectSources({
          sources,
          stop_words: [],
          source_addition: true,
          proj_uuid: projectId,
          products: [...products, ...existingProductsPayload],
        })
        toast.success('Sources were successfully added')
        handleClose()
      } catch (e: any) {
        toast.error(e.message)
      }
    },
  })
  const submit = () => send(ProjectActions.SUBMIT)

  useEffect(() => {
    if (current.matches(ProjectStates.FORM) && !existingProducts) {
      refetch()
    }
  }, [current.value])

  return (
    <ModalWrapper>
      <ModalHeader
        handleClose={handleClose}
        handleBack={
          current.context.backButton && (() => send(ProjectActions.BACK))
        }
      />
      {current.matches(ProjectStates.SELECTOR) && (
        <Selector
          onBlankClick={() => send(ProjectStates.FORM)}
          onCSVClick={() => send(ProjectStates.UPLOAD)}
        />
      )}
      {current.matches(ProjectStates.UPLOAD) && (
        <CsvForm
          onSuccess={({ sources, products }) => {
            send(ProjectStates.FORM)
            formik.setFieldValue('sources', sources)
            formik.setFieldValue('products', products)
          }}
        />
      )}
      {current.matches(ProjectStates.FORM) && (
        <EditProject
          isLoading={isLoading}
          title={details?.title}
          formik={formik}
          handleOpenProduct={() => send(ProjectStates.PRODUCT)}
          handleOpenSource={() => send(ProjectStates.SOURCE)}
          setSourceToEditIndex={setSourceToEditIndex}
          setProductToEditIndex={setProductToEditIndex}
        />
      )}
      {current.matches(ProjectStates.SOURCE) && (
        <SourceForm
          products={[
            ...formik.values.products,
            ...transformProducts(existingProducts || []),
          ]}
          reservedKeywords={[]}
          sourceNames={formik.values.sources.map((source) => source.title)}
          handleOpenProject={submit}
          addSource={(source) => {
            if (sourceToEditIndex !== null) {
              formik.values.sources[sourceToEditIndex] = source
              formik.setFieldValue('sources', formik.values.sources)
              setSourceToEditIndex(null)
            } else {
              formik.setFieldValue('sources', [
                ...formik.values.sources,
                source,
              ])
            }
          }}
          sourceToEdit={
            sourceToEditIndex !== null
              ? formik.values.sources[sourceToEditIndex]
              : null
          }
          sourceToEditIndex={sourceToEditIndex}
          setSourceToEditIndex={setSourceToEditIndex}
          deleteSource={() => {
            if (sourceToEditIndex !== null) {
              submit()
              formik.setFieldValue(
                'sources',
                formik.values.sources.filter((_, i) => sourceToEditIndex !== i)
              )
              setSourceToEditIndex(null)
            }
          }}
        />
      )}
      {current.matches(ProjectStates.PRODUCT) && (
        <ProductForm
          addProduct={(product) => {
            if (productToEditIndex !== null) {
              formik.values.products[productToEditIndex] = product
              formik.setFieldValue('products', formik.values.products)
              setProductToEditIndex(null)
            } else {
              formik.setFieldValue('products', [
                ...formik.values.products,
                product,
              ])
            }
          }}
          productToEdit={
            productToEditIndex !== null
              ? formik.values.products[productToEditIndex]
              : null
          }
          handleOpenProject={submit}
          deleteProduct={() => {
            if (productToEditIndex !== null) {
              submit()
              formik.setFieldValue(
                'products',
                formik.values.products.filter(
                  (_, i) => productToEditIndex !== i
                )
              )
              setProductToEditIndex(null)
            }
          }}
          setProductToEditIndex={setProductToEditIndex}
        />
      )}
    </ModalWrapper>
  )
}
