import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { OpenInNewOutlined } from '@mui/icons-material'
import {
  cardBorderGrey,
  secondaryNavy,
} from '../../../../../assets/styles/variables'
import { getCurrentRouteName } from '../../../utils'
import {
  initialState,
  useProjectStore,
} from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import { LogoSpinnerSmall } from '../../../../../components/LogoSpinnerSmall'
import { useQuery } from 'react-query'
import { getThemeSummary } from '../../charts/model'
import reactStringReplace from 'react-string-replace'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import summaryEmpty from '../../../../../assets/images/Summary-Empty.png'
import { Tooltip } from 'antd'
import mixpanel from '../../../../trackers/mixpanel'
import { HyperDiv } from '../../../../../components/HyperCopy/HyperDiv'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { ThemeSummary } from '../../charts/types'
import { Helpful } from './helpful'
import useSummary from '../../../hooks/useSummary'
import useThemes from '../../../hooks/useThemes'

type Props = {
  theme: ThemeSummary
  index: number
  summaryRequest: any
  sentiment?: string
  color?: string
  isAlwaysOpen?: boolean
  defaultOpened?: boolean
  isWidget?: boolean
}
const formatter = new Intl.NumberFormat()
export const Theme: React.FC<Props> = ({
  theme,
  index,
  summaryRequest,
  sentiment,
  isAlwaysOpen = false,
  defaultOpened = true,
  isWidget,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const chartData = useProjectStore((state: ProjectState) => state.chartData)
  const hasProductBreakdown = useMemo(
    () => chartData[0]?.tier1.options.find((el) => el.name === 'Product'),
    [chartData[0]?.tier1.options]
  )
  const summaryIsOpen = useProjectStore(
    (state: ProjectState) => state.summaryIsOpen
  )
  const [isOpen, setIsOpen] = useState(
    isAlwaysOpen || (index === 0 && defaultOpened)
  )

  const { getFilterFromChartClick } = useSummary()
  const { getNormalizedThemeName } = useThemes()

  const summaryEnabled = summaryIsOpen || isWidget

  const {
    isLoading: themeLoading,
    isFetching: themeFetching,
    data: themeSummary,
    isError: themeError,
  } = useQuery(
    ['theme-summary', summaryRequest, theme, sentiment],
    () => {
      const requestCopy = JSON.parse(JSON.stringify(summaryRequest))
      // widgets don't use the tier structure - thats tied to a charts element - so we delete and just supply the filters
      if (isWidget) {
        requestCopy.body.tier1 = null
      }
      const themeSummaryBody = getFilterFromChartClick(requestCopy, theme.theme)
      if (
        !themeSummaryBody.values.find((el: any) => el.field === 'sentiment')
      ) {
        if (!!sentiment) {
          const sentimentFilter = { field: 'sentiment', values: [sentiment] }
          themeSummaryBody.values.push(sentimentFilter)
        }
      }
      return getThemeSummary(projectId, {
        criteria: themeSummaryBody.values,
        search_terms: themeSummaryBody.searchQuery,
        search_criteria: themeSummaryBody.searchCondition,
      })
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled:
        summaryEnabled && !!summaryRequest && !!theme && !!defaultFilterList,
    }
  )

  const openFeedback = async (e: React.MouseEvent, keywords: string[]) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'
    const themeSummaryBody = getFilterFromChartClick(
      summaryRequest,
      theme.theme
    )
    const filters = {
      values: themeSummaryBody.values,
      searchQuery: keywords,
      // searchCondition: themeSummaryBody.search_criteria,
      searchCondition: ['or'],
    }
    const pageControls = initialState.feedbackControls
    const body = {
      filters: [filters],
      pageControls,
    }
    const filterHash = await getBookmarkUrlPost(projectId, {
      page: getCurrentRouteName(window.location.pathname),
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/feedback?filters=${filterHash}`
    window.open(url, '')
    document.body.style.cursor = ''
  }
  const openTheme = async (e: React.MouseEvent) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'
    const themeSummaryBody = getFilterFromChartClick(
      summaryRequest,
      theme.theme
    )
    const filters = {
      values: themeSummaryBody.values,
      searchQuery: [],
      // searchCondition: themeSummaryBody.search_criteria,
      searchCondition: [],
    }
    const pageControls = initialState.keywordsControls
    const body = {
      filters: [filters],
      pageControls,
    }
    const filterHash = await getBookmarkUrlPost(projectId, {
      page: getCurrentRouteName(window.location.pathname),
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/keywords?filters=${filterHash}`
    window.open(url, '')
    document.body.style.cursor = ''
  }
  const openProductBreakdown = async (e: React.MouseEvent) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'

    const themeSummaryBody = getFilterFromChartClick(
      summaryRequest,
      theme.theme
    )
    const filters = {
      values: themeSummaryBody.values,
      searchQuery: [],
      // searchCondition: themeSummaryBody.search_criteria,
      searchCondition: [],
    }
    const pageControls = JSON.parse(
      JSON.stringify(initialState.dashboardControls)
    )
    pageControls[0].tier1 = 'Product'
    pageControls[0].chartId = '6_5_17_sentiment'
    const body = {
      filters: [filters],
      pageControls,
    }
    const filterHash = await getBookmarkUrlPost(projectId, {
      page: getCurrentRouteName(window.location.pathname),
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/dashboard?filters=${filterHash}`
    window.open(url, '')
    document.body.style.cursor = ''
  }
  const replaceKeywordsWithLinks = (paragraph: string, keywords: string[]) => {
    let formatted = paragraph
    keywords.forEach((keyword) => {
      // let regex = new RegExp(keyword + '?(\\w+)')
      // @ts-ignore
      formatted = reactStringReplace(formatted, keyword, (match, i) => (
        <a
          href={'#'}
          onClick={(e) => {
            mixpanel.track('summary theme', {
              action: 'keyword',
              value: keyword,
              theme: theme.theme,
              data: summaryRequest,
              proj_uuid: projectId,
            })
            openFeedback(e, [keyword])
          }}
        >
          {match}
        </a>
      ))
    })
    return formatted
  }

  // TODO if clicked, when info load, send additional mixpanel?

  const insightBody = themeSummary?.body

  if (!theme) return <></>

  return (
    <ThemeContainer color={theme?.color ?? secondaryNavy} selected={isOpen}>
      <Tooltip
        placement="rightTop"
        title={
          <>
            <div>Avg Sentiment: {theme.avg_sentiment}</div>
            <div>Sentiment Label: {theme.sentiment_label}</div>
            <div># of Mentions: {formatter.format(theme.mentions_count)}</div>
            <div>{theme.mentions_percent.toFixed(2)}% of mentions</div>
          </>
        }
      >
        <ThemeTitle
          onClick={() => {
            if (!isAlwaysOpen) {
              if (!isOpen) {
                mixpanel.track('summary theme', {
                  action: 'theme',
                  theme: theme.theme,
                  data: summaryRequest,
                  proj_uuid: projectId,
                })
              }
              setIsOpen(!isOpen)
            }
          }}
          selected={isOpen}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            {!!index && <div>#{index}</div>}

            <div>{getNormalizedThemeName(theme.theme)}</div>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>({theme.mentions_percent.toFixed(2)}&#65285;)</div>
            <div>{isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
          </div>
        </ThemeTitle>
      </Tooltip>
      {themeError ? (
        <ErrorBody selected={isOpen}>
          <EmptyWrapper style={{ width: '100%', marginBottom: '20px' }}>
            <EmptyImage src={summaryEmpty} />
            <EmptyMessage>
              Unable to generate theme summary. Please try again later!
            </EmptyMessage>
          </EmptyWrapper>
        </ErrorBody>
      ) : (
        <>
          <ThemeBody selected={isOpen}>
            {themeLoading || themeFetching ? (
              <ThemeLoadingWrapper>
                <LogoSpinnerSmall>
                  <p>Loading Insight</p>
                </LogoSpinnerSmall>
                <p style={{ fontSize: '14px' }}>
                  Please wait a few seconds while Yogi summarizes relevant
                  reviews
                </p>
              </ThemeLoadingWrapper>
            ) : (
              <ThemeContent>
                {/*<Divider style={{ margin: '0px 0px 10px' }} />*/}
                {!!insightBody?.length ? (
                  <div style={{ overflow: 'auto' }}>
                    {insightBody.map((el) => el.keywords).flat().length > 0 && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          // justifyContent: 'space-between',
                          marginBottom: '15px',
                          rowGap: '5px',
                          columnGap: '10px',
                        }}
                        data-html2canvas-ignore={'true'}
                      >
                        <YogiButton
                          type="ghost"
                          onClick={(
                            event: React.MouseEvent<Element, MouseEvent>
                          ) => openFeedback(event, [])}
                          style={{
                            height: '28px',
                            padding: '0px 8px ',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          View All Reviews{' '}
                          <OpenInNewOutlined fontSize={'inherit'} />
                        </YogiButton>{' '}
                        {hasProductBreakdown && (
                          <YogiButton
                            type="ghost"
                            onClick={(
                              event: React.MouseEvent<Element, MouseEvent>
                            ) => openProductBreakdown(event)}
                            style={{
                              height: '28px',
                              padding: '0px 8px ',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            View Product Breakdown{' '}
                            <OpenInNewOutlined fontSize={'inherit'} />
                          </YogiButton>
                        )}
                        <YogiButton
                          type="ghost"
                          onClick={(
                            event: React.MouseEvent<Element, MouseEvent>
                          ) =>
                            openFeedback(
                              event,
                              insightBody.map((el) => el.keywords).flat()
                            )
                          }
                          style={{
                            height: '28px',
                            padding: '0px 8px ',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          Open All Keywords{' '}
                          <OpenInNewOutlined fontSize={'inherit'} />
                        </YogiButton>
                        <YogiButton
                          type="ghost"
                          onClick={(
                            event: React.MouseEvent<Element, MouseEvent>
                          ) => openTheme(event)}
                          style={{
                            height: '28px',
                            padding: '0px 8px ',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          Open Theme <OpenInNewOutlined fontSize={'inherit'} />
                        </YogiButton>
                      </div>
                    )}
                    {themeSummary?.reviews_only && (
                      <>
                        <h3 style={{ marginBottom: '5px 0px' }}>
                          Sentences Mentioning Theme:
                        </h3>
                      </>
                    )}
                    {insightBody.map((el, index) => (
                      <ThemeInfoCard elementName={'theme-summary'}>
                        {/*<Divider style={{ margin: '5px 0px' }} />*/}
                        {el.body.map((paragraph, pIndex) => {
                          if (
                            pIndex === 0 &&
                            !themeSummary?.reviews_only &&
                            !!paragraph
                          ) {
                            return (
                              <h3 style={{ marginBottom: '5px 0px' }}>
                                {replaceKeywordsWithLinks(
                                  paragraph,
                                  el.keywords
                                )}
                                :
                              </h3>
                            )
                          }
                          return (
                            <ThemeParagraph>
                              {replaceKeywordsWithLinks(paragraph, el.keywords)}
                            </ThemeParagraph>
                          )
                        })}
                        {el.keywords.length > 1 && (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                            }}
                            data-html2canvas-ignore={'true'}
                          >
                            <YogiButton
                              type="ghost"
                              onClick={(
                                event: React.MouseEvent<Element, MouseEvent>
                              ) => openFeedback(event, el.keywords)}
                              style={{
                                height: '28px',
                                padding: '0px 8px ',
                              }}
                            >
                              Open All Keywords
                            </YogiButton>
                          </div>
                        )}
                      </ThemeInfoCard>
                    ))}
                  </div>
                ) : (
                  <ErrorBody selected={isOpen}>
                    <EmptyWrapper
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      <EmptyImage src={summaryEmpty} />
                      <EmptyMessage>
                        Too few reviews available for Theme Analysis
                      </EmptyMessage>
                    </EmptyWrapper>
                  </ErrorBody>
                )}
              </ThemeContent>
            )}
          </ThemeBody>

          {!themeLoading &&
            !themeFetching &&
            !!insightBody?.length &&
            isOpen && (
              <HelpfulContainer>
                <Helpful
                  data={summaryRequest}
                  type={'theme'}
                  action={'callout'}
                />
              </HelpfulContainer>
            )}
        </>
      )}
    </ThemeContainer>
  )
}
const ThemeContainer = styled.div<{
  color?: string
  selected?: boolean
}>`
  width: 100%;
  background: #fcfafa;
  border: ${(props) =>
    props.color ? '2px solid ' + props.color : '2px solid ' + cardBorderGrey};
  //box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);
  border-radius: 8px;
  //border-top-left-radius: 28px;
  padding: 0px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  border-left-width: 10px;
  border-left-width: ${(props) => (props.selected ? '2px' : '10px')};
`
const ThemeTitle = styled.div<{ color?: string; selected?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 20px;
  gap: 10px;
  border-bottom: ${(props) =>
    props.selected ? '2px solid ' + cardBorderGrey : ''};
`
const ThemeContent = styled.div`
  margin: 10px 0px 0px;
`
const ErrorBody = styled.div<{ color?: string; selected?: boolean }>`
  display: flex;
  align-item: center;
  overflow: ${(props) => (props.selected ? 'auto' : 'hidden')};
  max-height: ${(props) => (props.selected ? '300px' : '0px')};
  transition: 0.2s ease-in-out;
  padding: ${(props) => (props.selected ? '0px 20px' : '0px')};
`
const ThemeBody = styled.div<{
  color?: string
  selected?: boolean
}>`
  background: white;
  overflow: ${(props) => (props.selected ? 'auto' : 'hidden')};
  overflow: auto;
  max-height: ${(props) => (props.selected ? '400px' : '0px')};
  transition: 0.2s ease-in-out;
  padding: 0px 20px;
`
const ThemeLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 10px auto 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const ThemeParagraph = styled.div`
  margin-bottom: 5px;
`
export const EmptyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const EmptyImage = styled.img`
  max-width: 30%;
  margin-bottom: 20px;
`
const EmptyMessage = styled.div`
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #9fb7c3;
`
const ThemeInfoCard = styled(HyperDiv)`
  background: #fcfafa;
  border: 2px solid var(--card-border-grey);
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0px 0px 15px;
`
const HelpfulContainer = styled.div`
  padding: 0px 20px;
  border-top: 2px solid ${cardBorderGrey};
`
