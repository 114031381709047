import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  max-width: 350px;
  min-width: 350px;
  overflow: auto;
  padding-bottom: 20px;
  @media (max-width: 1600px) {
    max-width: 250px;
    min-width: 250px;
  }
`

export const InsightsListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;

  @media (max-width: 1300px) {
    gap: 10px;
  }
`
