import { Filters } from '../../types'
import { axiosInstance } from '../../../../utils/axios'
import {
  OUTLIER_ANALYSIS_URL,
  OUTLIERS_URL,
  TIMESERIES_OUTLIERS_URL,
} from '../../../../utils/apiLinks'

type OutlierResponse = {
  outliers: OutlierType[]
  posts: any[]
}

export type OutlierPostTextBody = {
  post_text: string
  sentiment: number
}

export type OutlierPostBody = {
  body: string
  brand: string
  product: string
  date: number
  score: number
  texts: OutlierPostTextBody[]
}

export type Context = {
  context: string
  value: any
}

export type DateRange = {
  start_date: number
  end_date: number
}

export type OutlierType = {
  aggregation?: 'quarter' | 'month' | 'week' | 'day'
  analysis: OutlierAnalysisType
  bodies: OutlierPostBody[]
  context_with_value: Context
  create_time: number
  date?: string // timeseries
  date_range: DateRange
  mean_weighted_metric_score: number
  mean_metric_score: number
  metric: string
  metric_score: number
  type: string
  theme?: string
  uuid: string
  volume?: number
  weighted_metric_score: number
  weighted_zscore: number
  window_start_date?: string // timeseries
}

export type AnalysisSection = {
  title: string
  explanation: string
  evidence: string
  implication: string
}

export type OutlierAnalysisType = {
  stinger: string
  header: string
  subheader: string
  key_phrases: string[]
  body: AnalysisSection[]
}

export const getOutliers = async (
  proj_uuid: string,
  filterValues: Filters
): Promise<OutlierResponse> => {
  const { data } = await axiosInstance.post(
    OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      // context: 'brand',
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getTimeSeriesOutliers = async (
  proj_uuid: string,
  filterValues: Filters
): Promise<OutlierResponse> => {
  const { data } = await axiosInstance.post(
    TIMESERIES_OUTLIERS_URL,
    {
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getOutlierAnalysis = async (
  proj_uuid: string,
  outlier: OutlierType,
  reviews: any[],
  model: string,
  user_prompt?: string
): Promise<OutlierType> => {
  if (!!outlier.analysis) return outlier
  const { data } = await axiosInstance.post(
    OUTLIER_ANALYSIS_URL,
    {
      outlier,
      reviews,
      model,
      prompt_override: user_prompt,
    },
    { params: { proj_uuid } }
  )
  // try to parse, throw error if not parseable?
  try {
    return { ...outlier, analysis: JSON.parse(data) }
  } catch (e) {
    // console.log('Error parsing analysis data', e)
    // console.log(data)
    throw e
  }
}
