import React from 'react'
import SelectItem from '../SelectItem/SelectItem'
import {
  AppliedFilterOption,
  ApplyGroupFilterPayload,
  FilterOptionValue,
} from '../../../../../../types'
import { Virtuoso } from 'react-virtuoso'

type Props = {
  items: FilterOptionValue[]
  currentAppliedFilter: AppliedFilterOption | undefined
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  isParent?: boolean
  storedValues: FilterOptionValue[]
}

const VirtualList: React.FC<Props> = ({
  items,
  currentAppliedFilter,
  applySelect,
  storedValues,
}) => {
  const Row: React.FC<{ index: number }> = ({ index }) => (
    <SelectItem
      value={items[index]}
      currentAppliedFilter={currentAppliedFilter}
      applySelect={applySelect}
      storedValues={storedValues}
    />
  )

  if (items.length <= 1000) {
    return (
      <>
        {items.map((value) => (
          <SelectItem
            key={value.cluster_uuid ?? value.value}
            value={value}
            currentAppliedFilter={currentAppliedFilter}
            applySelect={applySelect}
            storedValues={storedValues}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <Virtuoso
        style={{ height: 250 }}
        totalCount={items.length}
        itemContent={(index) => <Row index={index} />}
      />
    </>
  )
}

export default VirtualList
