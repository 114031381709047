import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useClickOutside } from 'shared/hooks'
import {
  HardcodedFilterOptionsEnum,
  updateFilterList,
} from '../../../../filters/helpers'
import { PanelBadges } from '../../../../filters/features/badge-panel/panel-badges'
import { FilterPanel } from '../../../../filters/features/FilterPanel/FilterPanel'
import { SearchPanel } from '../../../../filters/features/search-panel/search-panel'
import { isInitialFlow } from '../../../../../../home/utils'
import Draggable from 'react-draggable'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { FilterOption, FilterOptionRange } from '../../../../filters/types'
import { FilterButton } from '../../../../filters/FilterButton'
import { useLocalFilterList } from '../../../../../hooks/useLocalFilterList'
import { OldDatePanel } from '../../../../filters/features/date-panel/old-date-panel'

type Props = {
  filterPanelHeight?: string
  filterValues: Filters
  mergedFilters: Filters
  parentFilters: Filters
  comparativeIndex: number
  updateLocalFilters: (filters: Filters) => void
  disabled?: boolean
  isEditing: boolean
  isWidget?: boolean
  isUnlinked: boolean
}

export const ChartHeaderFilterPanel = ({
  filterPanelHeight,
  filterValues,
  mergedFilters,
  parentFilters,
  comparativeIndex,
  updateLocalFilters,
  isEditing,
  isWidget,
  isUnlinked,
  disabled,
}: Props) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const defaultTime = defaultFilterList.find(
    (el) => el.field === 'create_time'
  ) as FilterOptionRange

  const panelRef = React.createRef<HTMLDivElement>()
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)
  const [dragged, setDragged] = useState(false)
  const [dragDisabled, setDragDisabled] = useState(true)
  const isSmall = true

  const [localOpenedOptions, setLocalOpenedOptions] = useState<string[]>([])
  const [filterList, setFilterList] = useState<FilterOption[]>([])
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(false)
  const [filterApplied, setFilterApplied] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')

  const { data: filterListData, isLoading } = useLocalFilterList(
    mergedFilters,
    selectedOption,
    localOpenedOptions,
    filterList,
    setFilterList
  )

  useEffect(() => {
    setFilterList(filterListData ?? [])
    setLocalOpenedOptions([])
    setSelectedOption('')
  }, [filterValues])

  useEffect(() => {
    if (filterListData) {
      // this caches the filter on a per-field basis
      setFilterList((prevState) =>
        updateFilterList(prevState, localOpenedOptions, filterListData)
      )
    }
  }, [filterListData])

  useEffect(() => {
    if (filterApplied && isLoading) {
      setFilterOptionsLoading(true)
    } else {
      setFilterApplied(false)
      setFilterOptionsLoading(false)
    }
  }, [filterApplied, isLoading])

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button') ||
      dragged
    )
      return
    setFilterPanelVisible(false)
    // updateSelectedOptionField(comparativeIndex, '')
  })

  if (isInitialFlow(details.state)) {
    return <Wrapper />
  }

  return (
    <OuterContainer className={'drag-cancel'}>
      <ResizeContainer>
        <Container>
          <Wrapper>
            <Left style={{ gap: isSmall ? 5 : 10 }}>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <FilterButton
                  filterValues={filterValues}
                  isFilterPanelVisible={isFilterPanelVisible}
                  setFilterPanelVisible={setFilterPanelVisible}
                  filterOptionsLoading={filterOptionsLoading || !filterList}
                  isSmall={isSmall}
                  filterList={filterListData ?? []}
                  disabled={disabled}
                />
              </span>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <Badge count={filterValues.searchQuery?.length} size="small">
                  <YogiButton
                    id="search-button"
                    type="ghost"
                    onClick={() => setSearchPanelVisible(!isSearchPanelVisible)}
                    style={{ padding: isSmall ? '0 10px' : '' }}
                    disabled={disabled}
                  >
                    {isSmall ? (
                      <span>
                        <SearchOutlined />
                      </span>
                    ) : (
                      <span>
                        <SearchOutlined
                          style={{
                            pointerEvents: 'none',
                            marginLeft: -3,
                            marginRight: 4,
                          }}
                        />{' '}
                        Search
                      </span>
                    )}
                  </YogiButton>
                </Badge>
              </span>
              <div>
                <PanelBadges
                  filterValues={filterValues}
                  comparativeIndex={comparativeIndex}
                  small={isSmall}
                  openFilterPanel={(field) => {
                    setSelectedOption(field)
                    setLocalOpenedOptions(
                      // @ts-ignore
                      ...new Set([...localOpenedOptions, field])
                    )
                    setFilterPanelVisible(true)
                  }}
                  updateLocalFilterValues={updateLocalFilters}
                  infoOnly={disabled}
                />
              </div>
              {isSearchPanelVisible && (
                <SearchPanelWrapper>
                  <SearchPanel
                    filterValues={filterValues}
                    closePanel={() => setSearchPanelVisible(false)}
                    comparativeIndex={comparativeIndex}
                    updateLocalFilterValues={updateLocalFilters}
                  />
                </SearchPanelWrapper>
              )}
            </Left>
            <Right style={{ gap: isSmall ? 5 : 10 }}>
              <div className={'drag-cancel'}>
                <OldDatePanel
                  comparativeIndex={0}
                  filterList={filterList}
                  filterValues={filterValues}
                  updateLocalFilterValues={updateLocalFilters}
                  isChild
                  isUnlinked={isUnlinked}
                  parentMin={
                    (
                      parentFilters?.values?.find(
                        ({ field }) =>
                          field === HardcodedFilterOptionsEnum.CREATE_TIME
                      ) as FilterOptionRange
                    )?.min ?? defaultTime?.min
                  }
                  parentMax={
                    (
                      parentFilters?.values?.find(
                        ({ field }) =>
                          field === HardcodedFilterOptionsEnum.CREATE_TIME
                      ) as FilterOptionRange
                    )?.max ?? defaultTime?.max
                  }
                  isLeft
                  disabled={disabled}
                />
              </div>
            </Right>
          </Wrapper>
        </Container>
      </ResizeContainer>
      {isFilterPanelVisible && (
        <Draggable onStart={() => setDragged(true)} disabled={dragDisabled}>
          <FilterPanelWrapper
            ref={panelRef}
            params={{
              isEditing: isEditing,
              isWidget: !!isWidget,
              dragged: dragged,
            }}
          >
            <FilterPanel
              onClose={() => {
                setFilterPanelVisible(false)
                setDragged(false)
                // updateSelectedOptionField(comparativeIndex, '')
              }}
              height={filterPanelHeight}
              dragged={dragged}
              setDragDisabled={setDragDisabled}
              comparativeIndex={comparativeIndex}
              filterValues={filterValues}
              updateLocalFilterValues={updateLocalFilters}
              filterListData={filterListData}
              localOpenedOptions={localOpenedOptions}
              setLocalOpenedOptions={setLocalOpenedOptions}
              localSelectedOption={selectedOption}
              setLocalSelectedOption={setSelectedOption}
              isUnlinked={isUnlinked}
              parentFilters={parentFilters}
            />
          </FilterPanelWrapper>
        </Draggable>
      )}
    </OuterContainer>
  )
}

type FilterWrapperProps = {
  params: {
    isEditing: boolean
    isWidget: boolean
    dragged: boolean
  }
}

const OuterContainer = styled.div`
  cursor: default;
`

const ResizeContainer = styled.div`
  width: 100%;
  position: relative;
`

const Container = styled.div`
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
  padding: 0;
  width: calc(100% - 30px);
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  .ant-badge-count {
    top: 4px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .ant-badge-count {
    right: 7px;
    top: 3px;
  }
`

const FilterPanelWrapper = styled.div`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: ${(props: FilterWrapperProps) =>
    props.params.isEditing ? '0px' : '10px'};
  //  this is stupid
  top: ${(props: FilterWrapperProps) => {
    if (props.params.isWidget === props.params.isEditing) {
      return '90px'
    } else if (props.params.isWidget) {
      return '100px'
    } else if (props.params.isEditing) {
      return '95px'
    }
  }};
  z-index: 10;
  box-shadow: var(--header-box-shadow);

  width: 100%;
  max-width: 800px;

  transform: ${(props: FilterWrapperProps) =>
    !props.params.dragged ? 'none !important' : 'inherit'};
`
const SearchPanelWrapper = styled.div`
  min-width: 420px;
  max-width: 500px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: var(--header-box-shadow);
`
