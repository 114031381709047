import { TIMESERIES_TYPE_NAME } from '../../../../constants'
import moment from 'moment/moment'
import { OutlierType } from '../../../../model'
import React from 'react'

type Props = {
  outlier: OutlierType
}

const InsightSubtitle: React.FC<Props> = ({ outlier }) => {
  if (outlier.type === TIMESERIES_TYPE_NAME) {
    return (
      <div>
        In the period between{' '}
        {outlier.date ? new Date(outlier.date).toDateString() : ''} -{' '}
        {moment(outlier.date)
          .add(1, outlier.aggregation)
          .toDate()
          .toDateString()}
        , the average {outlier.metric} for the selected data was{' '}
        {outlier.metric_score.toFixed(2)}, while the average {outlier.metric}{' '}
        from{' '}
        {outlier.window_start_date
          ? new Date(outlier.window_start_date).toDateString()
          : ''}{' '}
        - {outlier.date ? new Date(outlier.date).toDateString() : ''} was{' '}
        {outlier.mean_metric_score.toFixed(2)}.
      </div>
    )
  } else {
    return (
      <div>
        In the theme of <span style={{ fontWeight: 500 }}>{outlier.theme}</span>
        , {outlier.context_with_value.value} has an average {outlier.metric} of{' '}
        {outlier.weighted_metric_score.toFixed(2)}, while the average{' '}
        {outlier.metric} of all {outlier.context_with_value.context}s is{' '}
        {outlier.mean_weighted_metric_score.toFixed(2)}.
      </div>
    )
  }
}

export default InsightSubtitle
