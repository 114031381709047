import React from 'react'
import { OutlierType } from '../../../../model'
import {
  Container,
  Header,
  Info,
  InfoItem,
  Title,
} from './InsightListItem.styles'
import { getOutlierTitle } from '../../../../utils'
import FavoriteInsightButton from '../../../FavoriteInsightButton/FavoriteInsightButton'
import { Filters } from '../../../../../../types'
import useThemes from '../../../../../../hooks/useThemes'
import { TIMESERIES_TYPE_NAME } from '../../../../constants'
import moment from 'moment'
import mixpanel from '../../../../../../../trackers/mixpanel'

interface Props {
  outlier: OutlierType
  filterValues: Filters
  isSelected: boolean
  setSelectedOutlier: (outlier: OutlierType) => void
}

const InsightListItem: React.FC<Props> = ({
  outlier,
  filterValues,
  isSelected,
  setSelectedOutlier,
}) => {
  const { getNormalizedThemeName } = useThemes()

  const handleSelect = () => {
    setSelectedOutlier(outlier)
    mixpanel.track('automated insights', {
      action: 'select insight',
      value: getOutlierTitle(outlier),
    })
  }

  return (
    <Container isSelected={isSelected} onClick={handleSelect}>
      <Header isSelected={isSelected}>
        <Title>{getOutlierTitle(outlier)}</Title>
        <FavoriteInsightButton
          key={outlier.uuid}
          outlier={outlier}
          filterValues={filterValues}
        />
      </Header>
      <Info isSelected={isSelected}>
        {outlier.type === TIMESERIES_TYPE_NAME ? (
          <>
            <InfoItem style={{ flexBasis: '100%' }}>
              {!!outlier.date && new Date(outlier.date).toDateString()} -{' '}
              {outlier.aggregation && (
                <>
                  {moment(outlier.date)
                    .add(1, outlier.aggregation)
                    .toDate()
                    .toDateString()}
                </>
              )}
            </InfoItem>
          </>
        ) : (
          <>
            <InfoItem>{outlier.context_with_value?.value}</InfoItem>
            <InfoItem>
              {outlier.theme ? getNormalizedThemeName(outlier.theme) : ''}
            </InfoItem>
          </>
        )}
      </Info>
    </Container>
  )
}

export default InsightListItem
