import styled from 'styled-components'
import {
  lightBlueFontColor,
  selectableCardBackgroundNotSelected,
  selectableCardBackgroundSelected,
  selectableCardBorderNotSelected,
  selectableCardBorderSelected,
} from '../../../../../../../../assets/styles/variables'

export const Container = styled.div<{ isSelected: boolean }>`
  padding: 20px;
  background-color: ${({ isSelected }) =>
    isSelected
      ? selectableCardBackgroundSelected
      : selectableCardBackgroundNotSelected};
  border: 1px solid
    ${({ isSelected }) =>
      isSelected
        ? selectableCardBorderSelected
        : selectableCardBorderNotSelected};
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;

  &:hover {
    border-color: #486794;
  }

  transition: all 0.2s;
`

export const Header = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: ${({ isSelected }) => (isSelected ? '#fff' : lightBlueFontColor)};
`

export const Title = styled.div`
  font-size: 16px;
  line-height: 1.75;
  flex-grow: 2;
`

export const Info = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#797b7f')};
`

export const InfoItem = styled.div`
  flex-basis: 50%;
`
