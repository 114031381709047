import React from 'react'
import { Container, Tab, Tabs } from './InsightListOptions.styles'
import mixpanel from '../../../../../../../trackers/mixpanel'

const tabs = ['All', 'Recent', 'Most Reviews', 'Bookmarks']

interface Props {
  activeTab: number
  setActiveTab: (index: number) => void
}

const InsightListOptions: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (index: number) => {
    mixpanel.track('automated insights', {
      action: 'change list tab',
      value: tabs[index],
    })
    setActiveTab(index)
  }

  return (
    <Container>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => handleTabClick(index)}
            isSelected={activeTab === index}
          >
            {tab}
          </Tab>
        ))}
      </Tabs>
    </Container>
  )
}

export default InsightListOptions
