import React from 'react'
import styled from 'styled-components'
import { Button, Form } from 'antd'
import { FormikProps } from 'formik'
import { Loader } from 'shared/components'
import { FormAdditionalValues } from '../types'
import { CardNew } from './card-new'
import ProjectSourceVirtualizedList from './ProjectSourceVirtualizedList/ProjectSourceVirtualizedList'

type Props = {
  title?: string
  isLoading: boolean
  handleOpenSource: () => void
  handleOpenProduct: () => void
  formik: FormikProps<FormAdditionalValues>
  setSourceToEditIndex: (n: number) => void
  setProductToEditIndex: (n: number) => void
}

export const EditProject: React.FC<Props> = ({
  title,
  formik,
  isLoading,
  handleOpenProduct,
  handleOpenSource,
  setSourceToEditIndex,
  setProductToEditIndex,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <HorizontalContainer>
        <div style={{ flexGrow: 2 }}>
          <Section>
            <Form.Item
              label="Products"
              style={{ width: '100%', marginBottom: 0 }}
            >
              <div style={{ display: 'flex' }}>
                <Sources>
                  <SourceCardWrapper onClick={handleOpenProduct}>
                    <CardNew text={'Add Product'} />
                  </SourceCardWrapper>
                </Sources>
                <ProjectSourceVirtualizedList
                  items={formik.values.products}
                  handleOpen={handleOpenProduct}
                  setEditIndex={setProductToEditIndex}
                  formik={formik}
                />
              </div>
            </Form.Item>
          </Section>
          <Section>
            <Form.Item
              label="Sources"
              validateStatus={!!formik.errors.sources ? 'error' : ''}
              help={formik.errors.sources}
              style={{ width: '100%', marginBottom: 0 }}
            >
              <div style={{ display: 'flex' }}>
                <Sources>
                  <SourceCardWrapper onClick={handleOpenSource}>
                    <CardNew />
                  </SourceCardWrapper>
                </Sources>
                <ProjectSourceVirtualizedList
                  items={formik.values.sources}
                  handleOpen={handleOpenSource}
                  setEditIndex={setSourceToEditIndex}
                  formik={formik}
                />
              </div>
            </Form.Item>
          </Section>
        </div>
        <div>
          <Form.Item style={{ marginTop: 'auto', marginBottom: 0 }}>
            <SubmitButton
              type="primary"
              htmlType="submit"
              onClick={formik.submitForm}
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Create
            </SubmitButton>
          </Form.Item>
        </div>
      </HorizontalContainer>
      {isLoading && (
        <Overlay>
          <Loader />
        </Overlay>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 800px;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: auto;
    min-width: 300px;
  }
`
const Sources = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
const SourceCardWrapper = styled.div`
  width: 100%;
  height: 150px;
  padding: 10px;
  display: flex;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
const SubmitButton = styled(Button)`
  display: block;
  margin: var(--default-padding) auto 0;
`
const Title = styled.h1`
  text-align: center;
`
const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Section = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 16px;
`

const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`
